import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const defaultTheme = createTheme(theme, {
  components: {
  },
});

export { defaultTheme };
